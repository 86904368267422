.wrapper {
  opacity: 0;
  animation: fadeIn 1s ease both;
}

.wrapper.second {
  animation-delay: 0.5s;
}

.wrapper.third {
  animation-delay: 1s;
}

.wrapper.fourth {
  animation-delay: 1.5s;
}

.wrapper p {
  padding: 0 5%;
}

.icon {
  background-color: var(--c-cultured);
  border-radius: 50%;
  color: var(--c-indian-red);
  display: inline-block;
  line-height: 2em;
  width: 2em;
}

.link {
  color: var(--c-indian-red) !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
