.particles {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  animation-name: backgroundCarousel;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: normal;
}

@keyframes backgroundCarousel {
  0%,
  100% {
    background-image: url('https://isaaccolls.website/data/images/HomeBanner/home-bg-1.jpg');
  }
  33% {
    background-image: url('https://isaaccolls.website/data/images/HomeBanner/home-bg-2.jpg');
  }
  66% {
    background-image: url('https://isaaccolls.website/data/images/HomeBanner/home-bg-5.jpg');
  }
}
