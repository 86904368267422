.open {
  color: var(--c-corn) !important;
}

.icon {
  color: var(--c-indian-red);
  background-color: var(--c-cultured) !important;
}

.icon2 {
  color: var(--c-indian-red);
  background-color: transparent !important;
}

.start {
  color: var(--c-corn);
  background-color: var(--c-indian-red);
}

.date {
  color: var(--c-gunmetal);
}

.text {
  border-top: 3px solid var(--c-indian-red);
}

.link {
  color: var(--c-gunmetal-t) !important;
}

.linkDecoNone {
  text-decoration: none !important;
  color: inherit !important;
}

.toggle {
  box-shadow: none !important;
}

.toggle:focus {
  background-color: transparent !important;
  border-color: transparent !important;
}

.toggle:hover {
  background-color: var(--c-indian-red) !important;
}

.clients {
  background-color: var(--c-gunmetal-t);
  border-radius: 25px;
}

.client {
  filter: grayscale(100%);
}

.client:hover {
  filter: none;
}

.stack {
  color: var(--c-indian-red);
}
