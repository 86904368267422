.custom-card {
  border: none !important;
  background-color: transparent !important;
  cursor: pointer;
}

.custom-card-header {
  background-color: transparent !important;
  border: none !important;
}

.custom-accordion-toggle {
  background-color: transparent !important;
  border: none !important;
}

.custom-accordion-toggle:hover {
  background-color: var(--c-indian-red) !important;
  color: var(--c-platinum);
}

.custom-card-body {
  background-color: transparent !important;
  border: 1px solid var(--c-indian-red);
}

.indian-red {
  color: var(--c-indian-red);
}

.custom-star {
  opacity: 0;
  animation: fadeIn 1s ease both;
  animation-delay: 0.5s;
}

.custom-star-2 {
  animation-delay: 1s;
}

.custom-star-3 {
  animation-delay: 1.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
